import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { Virtuoso } from "react-virtuoso";

import { collection } from "../../Utils/Features/feedSlice";
import FeedDetailsCard from "../../components/FeedDetails/FeedDetailsCard/FeedDetailsCard";
import { Message } from "../../components/Common/Message";
import { setMenuChecked } from "../../Utils/Features/userConfigSlice";

const Collection = () => {
  const dispatch = useDispatch();
  const { collectionList } = useSelector((state) => state.feed);

  useEffect(() => {
    dispatch(collection());
  }, [dispatch]);

  useEffect(() => {
    dispatch(setMenuChecked("Collection"));
    // only on initial render:
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="feedParentContainer feedWrapperOverflow">
      <div className="feedBoxLayout">
        {collectionList?.length === 0 ? (
          <Message
            message="Oops! You have no collection yet!"
            instruction="Go like some items in feed, go go go!"
          />
        ) : (
          <Virtuoso
            totalCount={collectionList?.length}
            overscan={{ main: 800, reverse: 800 }}
            // TODO I might have overdone the styles.. but it should cover most browsers
            style={{
              overflowY: "scroll", // Enable scrolling within the Virtuoso component
              scrollbarWidth: "none", // Hide the scrollbar for Firefox
              msOverflowStyle: "none", // Hide the scrollbar for Internet Explorer/Edge
              WebkitOverflowScrolling: "touch", // Enable smooth scrolling on iOS devices
              WebkitScrollbar: {
                display: "none", // Hide the scrollbar for Chrome
              },
            }}
            itemContent={(index) => {
              const data = collectionList[index];
              return (
                <div key={index} id={`feed${index}`}>
                  <FeedDetailsCard feedDataList={data} bookId={data.book_id} />
                </div>
              );
            }}
          />
        )}
      </div>
    </div>
  );
};

export default Collection;
