import { useCallback, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import useMediaQuery from "@mui/material/useMediaQuery";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import { DeepTabs } from "../../components/Common/Tabs";
import ListView from "../ListView/ListView";
import TileView from "../TileView/TileView";
import BookDetails from "../../components/BookDetails/BookDetails";
import BookView from "../bookView/BookView";
import { setCurrentPageTabData } from "../../Utils/Features/currentPageTab";
import { getCurrentBook } from "../../Utils/Features/librarySlice";
import { AmazonSingleSync } from "../../components/Book/AmazonSingleSync";
import { SyncOrigin } from "../../components/Connectors/ChromeExtensionCommunication";
import { PrimaryButton } from "../../components/Common/Buttons/PrimaryButton";
import { generateSuggestions } from "../../Utils/Features/suggestionSlice";

const ViewTabs = [
  { label: "Book View", component: <BookView /> },
  { label: "List View", component: <ListView /> },
  { label: "Tiles View", component: <TileView /> },
];

const ViewTabMenu = () => {
  const dispatch = useDispatch();
  const currentPageTab = useSelector(
    (state) => state.currentPageTab.currentPageTab
  );
  const listViewData = useSelector((state) => state.library.listViewData);
  const book = useSelector((state) => getCurrentBook(state));
  const isMdUp = useMediaQuery((theme) => theme.breakpoints.up("md"));
  const startTab = 1;

  const user = useSelector((state) => state.auth.userData);

  const prevTabValueRef = useRef(null);
  const bookSelectRef = useRef(null);
  const tabRef = useRef(null);

  const scrollPosInitialRef = useRef(0);
  const isTransitioningRef = useRef(false);

  useEffect(() => {
    dispatch(setCurrentPageTabData(ViewTabs[startTab].label));
  }, [dispatch, startTab]);

  const handleChange = (val) => {
    const newTabLabel = ViewTabs[val].label;
    if (prevTabValueRef.current !== newTabLabel) {
      dispatch(setCurrentPageTabData(newTabLabel));
      prevTabValueRef.current = newTabLabel;
    }
  };

  useEffect(() => {
    if (!isMdUp) {
      const listViewElement = document.querySelector(".listViewWrapper");
      const tileViewElement = document.querySelector(".tileViewWrapper");

      if (listViewElement) {
        listViewElement.style.height = `calc(100vh - 75px)`;
      }
      if (tileViewElement) {
        tileViewElement.style.height = `calc(100vh - 75px)`;
      }
    }
  }, [currentPageTab]);

  const handleTopTransition = useCallback(
    (show) => {
      const listViewElement = document.querySelector(".listViewWrapper");
      const tileViewElement = document.querySelector(".tileViewWrapper");
      const highlightSyncElement = document.querySelector(".highlightSync");
      const showTopButtonElement = document.querySelector(".showTopButton");

      const bookSelect = bookSelectRef.current;
      const tabs = tabRef.current;

      const topBottomMargin = 5;
      const topBottomPadding = 16;

      const bookSelectHeight = bookSelect.offsetHeight;
      const tabTopHeight = 60 - topBottomMargin;
      const topHeight = bookSelectHeight + tabTopHeight;

      const deductibleHeight = isMdUp
        ? topBottomMargin + topBottomPadding
        : tabTopHeight + topBottomMargin + topBottomPadding;

      if (show) {
        showTopButtonElement.style.display = "none";
        bookSelect.style.top = "0";
        tabs.style.top = "0";
        if (highlightSyncElement) {
          highlightSyncElement.style.top = "60px";
          highlightSyncElement.classList.remove("up");
        }
        if (listViewElement) {
          listViewElement.style.height = `calc(100vh - ${
            topHeight + deductibleHeight
          }px)`;
          listViewElement.classList.remove("grow");
        }
        if (tileViewElement) {
          tileViewElement.style.height = `calc(100vh - ${
            topHeight + deductibleHeight
          }px)`;
          tileViewElement.classList.remove("grow");
        }
      } else {
        showTopButtonElement.style.display = "block";
        bookSelect.style.top = `-${2 * bookSelectHeight}px`;
        tabs.style.top = `-${topHeight}px`;
        if (highlightSyncElement) {
          highlightSyncElement.style.top = `-${0}px`;
          highlightSyncElement.classList.add("up");
        }
        if (listViewElement) {
          listViewElement.style.height = `calc(100vh - ${deductibleHeight}px)`;
          listViewElement.classList.add("grow");
        }
        if (tileViewElement) {
          tileViewElement.style.height = `calc(100vh - ${deductibleHeight}px)`;
          tileViewElement.classList.add("grow");
        }
        showTopButtonElement.style.display = "block";
      }
    },
    [isMdUp]
  );

  const handleScroll = useCallback(
    (e) => {
      const scrollPosCur = e.target.scrollTop;
      if (!isTransitioningRef.current) {
        if (scrollPosInitialRef.current > scrollPosCur) {
          handleTopTransition(true);
        } else if (scrollPosInitialRef.current < scrollPosCur) {
          handleTopTransition(false);
        }
      }
      scrollPosInitialRef.current = scrollPosCur;
    },
    [handleTopTransition]
  );

  const startHeightTransition = () => {
    isTransitioningRef.current = true;
  };
  const endHeightTransition = () => {
    isTransitioningRef.current = false;
  };

  useEffect(() => {
    const listViewElement = document.querySelector(".listViewWrapper");
    const tileViewElement = document.querySelector(".tileViewWrapper");
    if (listViewElement) {
      listViewElement.addEventListener("scroll", handleScroll);
      listViewElement.addEventListener(
        "transitionstart",
        startHeightTransition
      );
      listViewElement.addEventListener("transitionend", endHeightTransition);
    }
    if (tileViewElement) {
      tileViewElement.addEventListener("scroll", handleScroll);
      tileViewElement.addEventListener(
        "transitionstart",
        startHeightTransition
      );
      tileViewElement.addEventListener("transitionend", endHeightTransition);
    }

    return () => {
      if (listViewElement) {
        listViewElement.removeEventListener("scroll", handleScroll);
        listViewElement.removeEventListener(
          "transitionstart",
          startHeightTransition
        );
        listViewElement.removeEventListener(
          "transitionend",
          endHeightTransition
        );
      }
      if (tileViewElement) {
        tileViewElement.removeEventListener("scroll", handleScroll);
        tileViewElement.removeEventListener(
          "transitionstart",
          startHeightTransition
        );
        tileViewElement.removeEventListener(
          "transitionend",
          endHeightTransition
        );
      }
    };
  }, [listViewData, currentPageTab, handleScroll]);

  return (
    <div className={`w-full h-screen ${isMdUp ? "px-4" : "pl-0"}`}>
      <div ref={bookSelectRef} className="topTransition">
        <div>
          <BookDetails book={listViewData?.book?.[0]} pageTitle="listView" />
        </div>
        {user.isAdmin && (
          <div
            className={`highlightSync absolute z-[2] right-[172px] pt-[5px] top-[60px]`}
          >
            <PrimaryButton
              isPrimaryColor="true"
              onClick={() => {
                dispatch(generateSuggestions(book._id));
              }}
            >
              Suggestions
            </PrimaryButton>
          </div>
        )}
        {!book?.read_only && isMdUp && (
          <div
            className={`highlightSync absolute z-[2] right-[22px] pt-[10px] top-[60px]`}
          >
            <AmazonSingleSync
              bookData={book}
              originView={SyncOrigin.Highlight}
            />
          </div>
        )}
        <div
          className={`showTopButton absolute z-[1] right-[2px] top-[113px] pt-[6px] hidden w-full`}
        >
          <button
            onClick={handleTopTransition}
            className="border rounded w-96 h-[16px]"
            style={{
              borderTopColor: "transparent",
              display: "block",
              margin: "0 auto",
              width: "95%",
              backgroundColor: "var(--selectedBackgroundColor)",
            }}
          >
            <ExpandMoreIcon
              sx={{
                marginTop: "-9px",
              }}
            />
          </button>
        </div>
      </div>
      <div ref={tabRef} className="topTransition">
        <DeepTabs
          tabsInfo={ViewTabs}
          startTab={startTab}
          handleTabChange={handleChange}
          disableSwipe={isMdUp}
        >
          {ViewTabs.map((tab, index) => (
            <div key={index} style={{ paddingTop: "16px" }}>
              {tab.component}
            </div>
          ))}
        </DeepTabs>
      </div>
    </div>
  );
};

export default ViewTabMenu;
