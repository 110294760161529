import React from "react";
import { motion, AnimatePresence } from "framer-motion";
import { ProfileImage } from "../../Profile/ProfileImage.jsx";
import { iconProvider } from "../../../helperFunctions/iconProvider.js";

import "./Button.css";
import IconWithActivity from "../IconWithActivity.jsx";

export const PrimaryButton = ({
  isPrimaryColor,
  onClick,
  children,
  buttonClass,
}) => {
  return (
    <button
      className={`primaryButton ${
        isPrimaryColor && "primaryButtonColor"
      } ${buttonClass}`}
      onClick={onClick}
    >
      {children}
    </button>
  );
};

const LinkButton = ({ isOpen, item, isPrimaryColor }) => {
  const showAnimation = {
    hidden: {
      width: 0,
      opacity: 0,
      transition: {
        duration: 0.1,
      },
    },
    show: {
      opacity: 1,
      width: "auto",
      transition: {
        duration: 0,
      },
    },
  };

  return (
    <button
      id={item.active ? "activeMainMenu" : null}
      className={`${isOpen ? "link" : "linkCollapsible"} ${
        item.disabled ? "disable" : ""
      }
      ${isPrimaryColor && "primaryButtonColor"}`}
      onClick={item.onClick}
    >
      <span className="">
        {item.isActiveIcon ? (
          item.activityIconClass !== undefined ? (
            iconProvider(
              item.icon,
              item.isActive(item.name) ? item.activityIconClass : undefined
            )
          ) : (
            <IconWithActivity
              icon={iconProvider(item.icon)}
              hasActivity={item.isActive(item.name)}
            />
          )
        ) : item.icon === "profile_icon" ? (
          <ProfileImage isIcon="true" />
        ) : (
          iconProvider(item.icon)
        )}
      </span>
      <AnimatePresence>
        <motion.div
          variants={showAnimation}
          initial="hidden"
          animate="show"
          exit="hidden"
          className={!isOpen ? "link_text_collapse" : null}
        >
          {item.name}
        </motion.div>
      </AnimatePresence>
    </button>
  );
};

export default LinkButton;
