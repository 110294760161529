import { createSlice } from "@reduxjs/toolkit";
import { selectBook, selectPublicBook } from "./librarySlice";
import { fetchPostsByCategory } from "./wordpressSlice";
import { fetchSuggestionCount } from "./suggestionSlice";
import {
  like as likeSuggestion,
  dislike as dislikeSuggestion,
} from "./suggestionSlice";
import { collection, like } from "./feedSlice";

const initialState = {
  feedFilter: {
    filter: { search: "", elements: ["Ideas"] },
    sortFilter: "sample",
  },
  libraryFilter: {
    filter: { search: "" },
    sortFilter: "h_progress",
  },
  listViewFilter: {
    showHighlights: true,
  },
  tilesViewFilter: {
    showHighlights: true,
    chapterLevel: {
      label: "1st Level:Chapter",
      value: 1,
    },
  },
  newsLastRead: null,
  lastPostDate: null,
  newsLastChecked: null,
  newSuggestions: 0,
  suggestionsLastChecked: null,
  menuActivity: [],
};

export const userConfigSlice = createSlice({
  name: "userConfigSlice",
  initialState,
  reducers: {
    setFeedFilter: (state, action) => {
      state.feedFilter = action.payload;
    },
    setLibraryFilter: (state, action) => {
      state.libraryFilter = action.payload;
    },
    updateListViewFilter: (state, action) => {
      state.listViewFilter = { ...state.listViewFilter, ...action.payload };
    },
    updateTilesViewFilter: (state, action) => {
      state.tilesViewFilter = { ...state.tilesViewFilter, ...action.payload };
    },
    resetListViewFilter: (state) => {
      state.listViewFilter = initialState.listViewFilter;
    },
    resetTilesViewFilter: (state) => {
      state.tilesViewFilter = initialState.tilesViewFilter;
    },
    setNewsLastRead: (state) => {
      state.newsLastRead = new Date();
      removeMenuActivity(state, "Notifications");
    },
    setNewsLastChecked: (state) => {
      state.newsLastChecked = new Date();
    },
    setSuggestionsLastChecked: (state) => {
      state.suggestionsLastChecked = new Date();
    },
    setMenuChecked: (state, action) => {
      removeMenuActivity(state, action.payload);
    },
  },
  extraReducers(builder) {
    builder
      // new book selected, so reset the view filters:
      .addCase(selectBook.fulfilled, (state) => {
        state.listViewFilter = initialState.listViewFilter;
        state.tilesViewFilter = initialState.tilesViewFilter;
      })
      .addCase(selectPublicBook.fulfilled, (state) => {
        state.listViewFilter = initialState.listViewFilter;
        state.tilesViewFilter = initialState.tilesViewFilter;
      })
      .addCase(fetchPostsByCategory.fulfilled, (state, action) => {
        action.payload.forEach((post) => {
          if (state.lastPostDate === null || state.lastPostDate < post.date) {
            state.lastPostDate = post.date;
            if (
              state.newsLastRead === null ||
              new Date(state.lastPostDate) > new Date(state.newsLastRead)
            ) {
              addMenuActivity(state, "Notifications");
            }
          }
        });
      })
      .addCase(fetchSuggestionCount.fulfilled, (state, action) => {
        state.newSuggestions = action.payload.suggestionsCount;
        if (state.newSuggestions > 0) addMenuActivity(state, "Feed");
      })
      .addCase(likeSuggestion.fulfilled, (state) => {
        state.newSuggestions = state.newSuggestions - 1;
        if (state.newSuggestions <= 0) removeMenuActivity(state, "Feed");
        addMenuActivity(state, "Collection");
      })
      .addCase(like.fulfilled, (state) => {
        addMenuActivity(state, "Collection");
      })
      .addCase(dislikeSuggestion.fulfilled, (state) => {
        state.newSuggestions = state.newSuggestions - 1;
        if (state.newSuggestions <= 0) removeMenuActivity(state, "Feed");
      })
      .addCase(collection.fulfilled, (state, action) => {
        removeMenuActivity(state, "Collection");
      });
  },
});
export const {
  setFeedFilter,
  setLibraryFilter,
  updateListViewFilter,
  updateTilesViewFilter,
  resetListViewFilter,
  resetTilesViewFilter,
  setNewsLastRead,
  setNewsLastChecked,
  setSuggestionsLastChecked,
  setMenuChecked,
} = userConfigSlice.actions;
export const userConfigSliceReducer = userConfigSlice.reducer;
export default userConfigSlice;

const addMenuActivity = (state, menuItem) => {
  if (!state.menuActivity?.some((item) => item === menuItem))
    state.menuActivity.push(menuItem);
};

const removeMenuActivity = (state, menuItem) => {
  if (state.menuActivity.some((item) => item === menuItem)) {
    const newActivity = state.menuActivity.filter((item) => item !== menuItem);
    state.menuActivity = newActivity;
  }
};

export const isMenuActive = (state, menuItem) => {
  return state.userConfig.menuActivity?.some((item) => item === menuItem);
};
