import React from "react";
import { getValueByKey } from "../../helperFunctions/getValueByKey";
import { lowercaseAllButFirstLetter } from "../../helperFunctions/strings";
import { store } from "../../Utils/Store/Store";
import { getBookName } from "../../Utils/Features/librarySlice";
import { getAllIdeaIcons } from "../../helperFunctions/getIdeacardIcons";

const FilterSummary = ({ title, filter }) => {
  const sortEnum = {
    updated: "Last edited",
    created: "Last created",
    statistic: "Mingle mix",
    sample: "Random picks",
    suggestions: "Only suggestions",
    author: "Author",
    title: "Title",
    rating: "Rating",
    updated_at: "Last Edited",
    seen_at: "Last Viewed",
    h_progress: "Highlights",
  };

  return (
    <div>
      <div className="font-bold text-left ml-[10px]">{title}</div>
      <div className="text-left ml-[42px]">
        <ul className="items-start">
          {filter && filter?.filter?.search.length > 0 && (
            <li className="!list-disc">
              <p>
                <span className="font-medium">Search:</span>{" "}
                <span className="font-normal">{filter?.filter?.search}</span>
              </p>
            </li>
          )}

          {/* sort */}

          {filter && filter?.sortFilter?.length > 0 && (
            <li className="!list-disc">
              <p>
                <span className="font-medium">Sort:</span>{" "}
                <span className="font-normal">
                  {getValueByKey(sortEnum, filter?.sortFilter)}
                </span>
              </p>
            </li>
          )}

          {filter &&
            filter.filter &&
            Object.keys(filter.filter)
              .filter((item) => item !== "search")
              .map(
                (filterType) =>
                  filter.filter[filterType].length > 0 && (
                    <li className="!list-disc" key={filterType}>
                      <span>
                        <span className="font-medium">
                          {lowercaseAllButFirstLetter(filterType)}:{" "}
                        </span>
                        <span className="font-normal">
                          {filterType === "books" ? (
                            <ul className="pl-6">
                              {filter.filter[filterType].map((bookId) => (
                                <li
                                  style={{ listStyle: "circle" }}
                                  key={bookId}
                                >
                                  {getBookName(store.getState(), bookId)}
                                </li>
                              ))}
                            </ul>
                          ) : filterType === "elements" ? (
                            <ElementTypesList
                              elements={filter.filter[filterType]}
                            />
                          ) : (
                            <span>{filter.filter[filterType].join(", ")}</span>
                          )}
                        </span>
                      </span>
                    </li>
                  )
              )}
        </ul>
      </div>
    </div>
  );
};

export default FilterSummary;

const ElementTypesList = ({ elements }) => {
  const ideacardIcons = getAllIdeaIcons();
  const subElements = elements.filter(
    (item) => item !== "Highlights" && item !== "Ideas"
  );

  return (
    <ul className="pl-6">
      {elements.includes("Highlights") && (
        <li style={{ listStyle: "circle" }}>
          <span>Highlights</span>
        </li>
      )}
      {(elements.includes("Ideas") || subElements.length > 0) && (
        <li style={{ listStyle: "circle" }}>
          <span>
            Ideas
            {subElements.length > 0 && (
              <>
                : (
                {subElements
                  .map((item) => {
                    const icon = ideacardIcons?.filter(
                      (icon) => icon._id === item
                    );
                    return icon
                      ? lowercaseAllButFirstLetter(icon[0].label)
                      : "";
                  })
                  .join(", ")}
                )
              </>
            )}
          </span>
        </li>
      )}
    </ul>
  );
};
