import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

import { getHeaders } from "./authSlice";
import { getApiUrl } from "../../helperFunctions/envVars";
import toastMessage, { toastType } from "../../helperFunctions/toastMessage";

const apiUrl = getApiUrl();
const apiRoot = apiUrl + "/api";

const initialState = {};

export const like = createAsyncThunk("suggestion/like", async (id) => {
  const data = { id };
  const response = await axios.put(
    `${apiRoot}/suggestions/like`,
    data,
    getHeaders()
  );
  return {
    ...response.data.data,
  };
});

export const dislike = createAsyncThunk("suggestion/dislike", async (id) => {
  const data = { id };
  const response = await axios.put(
    `${apiRoot}/suggestions/dislike`,
    data,
    getHeaders()
  );
  return {
    ...response.data.data,
  };
});

export const fetchSuggestions = createAsyncThunk(
  "suggestion/fetch",
  async () => {
    const response = await axios.get(`${apiRoot}/suggestions/`, getHeaders());
    return response.data.data;
  }
);

export const fetchSuggestionCount = createAsyncThunk(
  "suggestion/count",
  async () => {
    const response = await axios.get(
      `${apiRoot}/suggestions/count`,
      getHeaders()
    );
    return response.data.data;
  }
);

export const generateSuggestions = createAsyncThunk(
  "suggestion/generate/book",
  async (bookId) => {
    const response = await axios.post(
      `${apiRoot}/suggestions/generate/book`,
      { book_id: bookId },
      getHeaders()
    );
    return response.data.data;
  }
);

export const suggestionSlice = createSlice({
  name: "suggestionSlice",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(like.fulfilled, (state, action) => {
      if (action.payload.ideaCreated) {
        toastMessage(
          toastType.SUCCESS,
          5000,
          <div>
            ✨ Great choice! <br /> We've added this suggestion to your book in
            the library, so you can easily revisit it anytime.
            <br /> Keep exploring and building your personalized collection!
          </div>
        );
      }
    });
  },
});

export const suggestionSliceReducer = suggestionSlice.reducer;
export default suggestionSliceReducer;
